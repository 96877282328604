<template>
  <div class="md:flex md:justify-around">
    <div class="mt-4">
      <div>
        <h1 class="font-semibold">Subscription Plan</h1>
        <div
          class="flex justify-between items-center border-2 p-4 mt-2"
        >
          <div class="flex items-center">
            <input
              class="cursor-pointer"
              type="radio"
              name="plan"
              checked
              @click="selectPlan('Month')"
            />
            <div class="ml-5">
              <p class="font-semibold">Month</p>
              <p class="text-sm">Per player</p>
            </div>
          </div>
          <div class="border-l-2 pl-2 text-sm text-gray-500">
            <p>Sub-total</p>
            <em v-if="gettingPrices" class="fa fa-spinner fa-spin ml-5"></em>
            <p v-else >USD {{ prices.monthly_price ?? 0 }} </p>
          </div>
        </div>
        <div
          class="flex justify-between items-center border-2 p-4 mt-2"
        >
          <div class="flex items-center">
            <input
              class="cursor-pointer"
              type="radio"
              name="plan"
              @click="selectPlan('Semester')"
            />
            <div class="ml-5">
              <p class="font-semibold">Six months</p>
              <p class="text-sm">Per player</p>
            </div>
          </div>
          <div class="border-l-2 pl-2 text-sm text-gray-500">
            <p>Sub-total</p>
            <em v-if="gettingPrices" class="fa fa-spinner fa-spin ml-5"></em>
            <p v-else >USD {{ prices.semiannual_price ?? 0 }} </p>
          </div>
        </div>
        <div
          class="flex justify-between items-center border-2 p-4 mt-2"
        >
          <div class="flex items-center">
            <input
              class="cursor-pointer"
              type="radio"
              name="plan"
              @click="selectPlan('Year')"
            />
            <div class="ml-5">
              <p class="font-semibold">Annual</p>
              <p class="text-sm">Per player</p>
            </div>
          </div>
          <div class="border-l-2 pl-2 text-sm text-gray-500">
            <p>Sub-total</p>
            <em v-if="gettingPrices" class="fa fa-spinner fa-spin ml-5"></em>
            <p v-else >USD {{ prices.annual_price ?? 0 }} </p>
          </div>
        </div>
      </div>
      <p class="font-semibold mt-3">Number of players</p>
      <div class="flex items-center border p-3 mt-2">
        <p class="w-1/2">${{ price }} per player</p>
        <div class="flex justify-end items-center">
          <p class="border py-0.5 px-1 cursor-pointer" @click="decrease()">
            <em class="fa-solid fa-minus"></em>
          </p>
          <input
            class="w-1/6 text-center border p-0.5"
            type="number"
            v-model="players"
            min="1"
          />

          <p class="border py-0.5 px-1 cursor-pointer" @click="increase()">
            <em class="fa-solid fa-plus"></em>
          </p>
        </div>
      </div>
      <div v-show="plan === 'Month'">
        <p class="text-xs max-w-sm italic mt-2 text-justify">
          *Please note you will be charged a pro-rated amount of the monthly
          charge for your initial payment. The first full payment will be
          charged on the first of the next month.
        </p>
      </div>
      <form id="stripe" @submit.prevent="handleSubmit" class="mt-5">
        <h5 class="font-semibold">Payment Details</h5>
        <div class="mt-1">
          <input
            class="mt-1 block w-full p-2 bg-grey-steps"
            type="text"
            placeholder="Name on card"
            :disabled="freeCost"
          />
        </div>
        <div class="">
          <div class="my-2 border p-4" ref="cardElement"></div>
        </div>
        <p class="text-danger">{{ stripeValidationError }}</p>
        <p class="text-danger" v-if="showCardError">
          There is a problem with your card, please try again!
        </p>
      </form>
    </div>
    <div class="md:w-6/12 mt-4">
      <p class="font-semibold">Discount Code</p>
      <div class="flex justify-between mt-2">
        <input
          style="background-color: #ecf0f5"
          v-model="discountCode"
          id="promotion-code"
          class="w-6/12 p-2"
          type="text"
          placeholder="code"
          required
        />
        <button
          id="b-pay"
          class="w-2/5 text-white p-1"
          style="background-color: #1d2e48"
          v-on:click="applyDiscountCode"
          :disabled="!discountCode || checkDiscountCode"
        >
          <em v-if="checkDiscountCode" class="fa fa-spinner fa-spin"></em>
          Apply
        </button>
      </div>
      <h6 v-if="discountCodeErrorText" class="text-red-600 mt-1">
        {{ discountCodeErrorText }}
      </h6>
      <div class="flex mt-4">
        <p class="font-semibold">Organization Code</p>
        <span class="info-icon ml-2" title="Your organization code"
          >&#x1F6C8;</span
        >
      </div>
      <div class="flex justify-between mt-2">
        <input
          style="background-color: #ecf0f5"
          v-model="facilityCode"
          id="organization-code"
          class="w-6/12 p-2"
          type="text"
          placeholder="code"
          required
        />
        <button
          id="b-pay"
          class="w-2/5 text-white p-1"
          style="background-color: #1d2e48"
          v-on:click="verifyFacilityCode"
          :disabled="!facilityCode || checkFacilityCode"
        >
          <em v-if="checkFacilityCode" class="fa fa-spinner fa-spin"></em>
          Verify
        </button>
      </div>
      <div class="flex justify-between mt-5 p-3 bg-grey-steps">
        <p class="font-semibold">Product</p>
        <p>Units</p>
        <p>Amount</p>
      </div>

      <div class="flex justify-between p-3">
        <p>Players</p>
        <p>{{ players }}</p>
        <p>${{ subTotal }}</p>
      </div>

      <div class="m-auto line"></div>

      <div class="flex justify-between p-3">
        <p>Discount ({{ percentageDiscount }}%)</p>
        <p>-${{ totalDiscount }}</p>
      </div>

      <div class="m-auto line"></div>

      <div class="flex justify-between mt-3 font-semibold text-2xl p-3">
        <p class="">Total</p>
        <h3>${{ totalPrice }}</h3>
      </div>

      <button
        id="b-pay"
        form="stripe"
        :disabled="proccessingPayment || gettingPrices"
        class="w-full mt-4 text-color font-semibold bg-mindaro p-2"
        type="submit"
      >
        <em v-if="proccessingPayment" class="fa fa-spinner fa-spin"></em>
        {{ freeCost || totalDiscount === price ? "Start Free" : "Pay" }}
      </button>
    </div>
    <Modal :show="showModal" @confirm="handleConfirm" :isConfirmDisabled="false" width="300px">
      <template v-slot:header>
        <h2 class="font-bold mb-4">{{ modalText }}</h2>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import {
  getPromotionCodes,
  createSubscription,
  createB2CUser,
  getStripeDiscountCode,
  getPlanPrices
} from "@/services/stripe/stripe.js";
import {
  locationPrices,
  validateFacilityCode,
} from "@/services/location/location.js";
import { triggerPurchaseEvent } from "@/services/gtmTracker/gtmTracker.js";
import Modal from "@/components/general/ConfirmationModal.vue";

const router = useRouter();
const route = useRoute();
const store = useStore();

// eslint-disable-next-line no-undef
const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
const cardElement = ref(null);
const cardObj = ref(null);
const players = ref(1);
const promotionCodesList = ref({});
const customerId = ref(null);
const email = ref("");
const phoneNumber = ref("")
const nameOnCard = ref("");
const stripeValidationError = ref("");
const price = ref(0);
const subTotal = ref(0);
const totalDiscount = ref(0);
const percentageDiscount = ref(0);
const totalPrice = ref(0);
const showCardError = ref(false);
const proccessingPayment = ref(false);
const plan = ref("Month");
const prices = ref({});
const freeCost = ref(false);
const facilityCode = ref("");
const validatedFacilityCode = ref("");
const checkFacilityCode = ref(false);
const isValidFacilityCode = ref(false);
const showModal = ref(false);
const modalText = ref("");
const discountCode = ref("");
const validatedDiscountCode = ref("");
const checkDiscountCode = ref(false);
const isDynamicDiscount = ref(false);
const discountCodeErrorText = ref("");
const isFreeTrial = ref(false);
const gettingPrices = ref(false);

onMounted(async () => {
  customerId.value = route.query.customerId;
  email.value = route.query.email
  phoneNumber.value = route.query.phoneNumber
  isFreeTrial.value = route.query.isFreeTrial

  await getDefaultPrices();
  await promotionCodes();
  initializateStripeElements();
});

function initializateStripeElements() {
  const elements = stripe.elements();
  const style = {
    base: {
      iconColor: "black",
      color: "black",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",

      ":-webkit-autofill": {
        color: "#F7F9FB",
      },
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
  };

  cardObj.value = elements.create("card", { style });
  cardObj.value.mount(cardElement.value);
  cardObj.value.on("change", setValidationError);
}

function increase() {
  players.value++;
}

function decrease() {
  if (players.value > 1) {
    players.value--;
  }
}

async function handleSubmit() {
  try {
    proccessingPayment.value = true;
    if (freeCost.value || totalDiscount.value === price.value) {
      await createB2CUser({
        customerId: customerId.value,
        quantity: players.value,
        coupon: freeCost.value ? "Free-cost" : validatedDiscountCode.value,
        facilityCode: isValidFacilityCode.value
          ? validatedFacilityCode.value
          : "",
      });

      if (checkFreeTrial()) {
        router.push({name: "Home"});
        return;
      }

      router.push({
        name: "Register players",
        query: { 
          n: players.value,
          registrationMethod: "free-cost"
        },
      });
    }
    if (players.value < 1) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardObj.value,
      billing_details: {
        name: nameOnCard.value,
      },
    });

    await subscriptionRequest(result.paymentMethod.id);

    if (checkFreeTrial()) {
      router.push({name: "Home"});
      return;
    }

    router.push({
      name: "Register players",
      query: { 
        n: players.value,
        registrationMethod: isValidFacilityCode.value
          ? "B2B"
          : "B2C"
      },
    });
    return;
  } catch (e) {
    proccessingPayment.value = false;
  }
}

function checkFreeTrial() {
  if (isFreeTrial.value) {
    if (players.value === 1) {
      store.commit("user/setIsFreeTrialExpired", false);   
      return true
    } else {
      players.value = players.value - 1;
    } 
  }
  return false;
}

async function subscriptionRequest(paymentMethodId) {
  const priceId = getPriceId(plan.value);
  let couponIdToSend = validatedDiscountCode.value;
  let discountPercentageToSend = percentageDiscount.value;

  if (
    (isDynamicDiscount.value && plan.value !== "Month") ||
    discountPercentageToSend === 0
  ) {
    couponIdToSend = "";
    discountPercentageToSend = 0;
  }

  const response = await createSubscription({
    customerId: customerId.value,
    paymentMethodId,
    priceId,
    quantity: players.value,
    couponId: couponIdToSend,
    discountPercentage: discountPercentageToSend,
    proration: false,
    facilityCode: isValidFacilityCode.value ? validatedFacilityCode.value : "",
    isFreeTrial: isFreeTrial.value,
  });

  if (response.success) {
    const subscriptionData = response.data.subscription;
    triggerPurchaseEvent(
      subscriptionData,
      validatedDiscountCode.value,
      isValidFacilityCode.value ? validatedFacilityCode.value : "",
      plan.value,
      email.value,
      phoneNumber.value
    );
  } else if (!response.success) {
    showCardError.value = true;
    throw new Error("Error proccessing subscription");
  }
}

function getPriceId(plan) {
  switch (plan) {
    case "Month":
      return prices.value.stripe_price_id_monthly;
    case "Semester":
      return prices.value.stripe_price_id_semiannual;
    case "Year":
      return prices.value.stripe_price_id_annual;
  }
}

async function promotionCodes() {
  try {
    const { data } = await getPromotionCodes();
    promotionCodesList.value = data;
  } catch (e) {
    throw new Error(error);
  }
}

async function getLocationPrices(facilityCode) {
  try {
    const { data } = await locationPrices(facilityCode);
    prices.value = data;
    freeCost.value = data.location.free_cost;
    selectPlan(plan.value);
    recalculateTotals();
  } catch (error) {
    throw new Error(error);
  }
}

async function getDefaultPrices() {
  try {
    
    gettingPrices.value = true;
    const data = await getPlanPrices();
    prices.value = data;
    selectPlan(plan.value);
    recalculateTotals();
  } catch (error) {
    throw new Error(error);
  } finally {
    gettingPrices.value = false;
  }
}

function recalculateTotals() {
  subTotal.value = calculateTotalPrice(price.value, players.value);
  totalPrice.value = calculateTotalPrice(price.value, players.value);
}

function selectPlan(planSelected) {
  const planPrices = {
    Month: prices.value.monthly_price,
    Semester: prices.value.semiannual_price,
    Year: prices.value.annual_price,
  };

  if (planPrices[planSelected]) {
    percentageDiscount.value = 0;
    totalDiscount.value = 0;
    updatePricing(planSelected, planPrices[planSelected]);
  }
}

function updatePricing(planSelected, pricePerPlan) {
  const formattedPrice = Number(pricePerPlan).toFixed(2);
  price.value = formattedPrice;
  totalPrice.value = calculateTotalPrice(formattedPrice, players.value);
  subTotal.value = totalPrice.value;
  plan.value = planSelected;
}

function calculateTotalPrice(price, playersCount) {
  return (price * playersCount).toFixed(2);
}

function setValidationError(event) {
  stripeValidationError.value = event.error ? event.error.message : "";
}

async function verifyFacilityCode() {
  try {
    checkFacilityCode.value = true;
    const { data: isValidCode } = await validateFacilityCode(
      facilityCode.value
    );

    if (isValidCode) {
      validatedFacilityCode.value = facilityCode.value;
      await getLocationPrices(facilityCode.value);
      isValidFacilityCode.value = true;
      modalText.value = "Valid code";
    } else {
      getDefaultPrices();
      freeCost.value = false;
      isValidFacilityCode.value = false;
      modalText.value = "Invalid code";
    }

    selectPlan(plan.value);

    recalculateTotals();
    if (discountCode.value) {
      await applyDiscountCode();
    }

    showModal.value = true;
  } catch (error) {
    showModal.value = true;
    modalText.value = "Error verifying code";
  } finally {
    checkFacilityCode.value = false;
  }
}

function handleConfirm() {
  showModal.value = false;
}

async function applyDiscountCode() {
  checkDiscountCode.value = true;

  discountCodeErrorText.value = "";

  if (!discountCode.value) return;

  try {
    const discountData = await getStripeDiscountCode(discountCode.value);

    validatedDiscountCode.value = discountCode.value;
    isDynamicDiscount.value = discountData.is_dynamic;

    totalDiscount.value = 0;
    percentageDiscount.value = 0;

    if (
      plan.value === "Month" &&
      discountData.is_dynamic &&
      discountData.expected_price != null &&
      discountData.expected_price <= price.value
    ) {
      const dynamicDiscount =
        ((price.value - discountData.expected_price) / price.value) * 100;
      const dynamicDiscountRounded = Number(dynamicDiscount.toFixed(2));
      const dynamicPriceOff = (subTotal.value * dynamicDiscount) / 100;
      const dynamicPriceOffRounded = Number(dynamicPriceOff.toFixed(2));

      applyDiscount(dynamicPriceOffRounded, dynamicDiscountRounded);
    } else if (!discountData.is_dynamic) {
      const staticDiscount = discountData.discount_percentage;
      const staticPriceOff = (subTotal.value * staticDiscount) / 100;

      applyDiscount(staticPriceOff, staticDiscount);
    }
  } catch (error) {
    validatedDiscountCode.value = "";
    discountCodeErrorText.value = "Discount code is not valid or active!";
    resetDiscount();
  } finally {
    checkDiscountCode.value = false;
  }
}

function applyDiscount(discountValue, discountPercentage) {
  totalDiscount.value = discountValue.toFixed(2);
  percentageDiscount.value = discountPercentage;
  totalPrice.value = (subTotal.value - discountValue).toFixed(2);
}

function resetDiscount() {
  totalDiscount.value = 0;
  percentageDiscount.value = 0;
  totalPrice.value = subTotal.value;
}

watch(players, (newValue) => {
  if (newValue < 1) {
    newValue = 1;
    players.value = 1;
  }

  subTotal.value = (price.value * newValue).toFixed(2);
  totalPrice.value = subTotal.value;

  if (discountCode.value) {
    applyDiscountCode();
  }
});

watch(freeCost, (newValue) => {
  if (cardObj.value) {
    cardObj.value.update({ disabled: newValue });
  }
});

watch(discountCode, (newValue) => {
  discountCodeErrorText.value = "";
});
</script>

<style lang="scss">
#b-pay:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.15em solid #1d2e48;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  transform: scale(0);
  border: 0.4em solid #1d2e48;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em red;

  + .contendero {
    border-color: green;
  }
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
</style>
